<template>
  <v-row>
    <v-col
      cols="12"
      md="8"
      v-if="isSimpleOrderType && !editMode && !orderTemplate"
    >
      <v-autocomplete
        outlined
        :items="newOrderCourseTypes"
        :disabled="disabled"
        label="Typ kursu"
        @input="emitUpdate('course.courseType', $event)"
        :value="courseData.courseType"
        append-icon="mdi-chevron-down"
        dense
      />
    </v-col>

    <v-col
      cols="12"
      md="4"
      v-if="isSimpleOrderType && !editMode"
    >
      <v-text-field
        outlined
        label="Liczba zleceń"
        @input="emitUpdate('order.quantity', +$event)"
        :value="orderData.quantity"
        type="number"
        :disabled="disabled"
        :rules="[rules.required, rules.integer, rules.lowerThan(11), rules.greaterThan(0)]"
        @wheel="$event.target.blur()"
        placeholder="-"
      />
    </v-col>

    <v-col
      md="4"
      cols="12"
    >
      <v-menu
        v-model="showDatePicker"
        v-bind="attributes.menu"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            :value="formatDate(courseData.dueDate)"
            :rules="showDatePicker ? [] : [rules.required]"
            class="pt-0 input--date-picker"
            label="Data realizacji"
            placeholder="Wybierz datę realizacji"
            :disabled="disabled"
            v-bind="{...attrs, ...attributes.textField}"
            v-on="on"
            @click.native="showDatePicker = !showDatePicker"
          >
            <template #prepend-inner>
              <Icon
                name="date"
                size="small"
                class="mr-2"
              />
            </template>
          </v-text-field>
        </template>
        <v-date-picker
          :value="courseData.dueDate"
          v-bind="attributes.datePicker"
          :disabled="disabled"
          :min="new Date().toISOString().substr(0, 10)"
          @input="emitUpdate('course.dueDate', $event);showDatePicker = false"
        />
      </v-menu>
    </v-col>

    <v-col
      md="4"
      cols="12"
      v-if="orderTemplate && !swap"
    >
      <v-menu
        v-model="showPickupDatePicker"
        v-bind="attributes.menu"
      >
        <template #activator="{ on, attrs }">
          <v-text-field
            :value="formatDate(pickupDate)"
            class="pt-0 input--date-picker"
            label="Data zabrania"
            placeholder="Datę zabrania"
            v-bind="{...attrs, ...attributes.textField}"
            :disabled="disabled"
            v-on="on"
            @click.native="showPickupDatePicker = !showPickupDatePicker"
          >
            <template #prepend-inner>
              <Icon
                name="date"
                size="small"
                class="mr-2"
              />
            </template>
          </v-text-field>
        </template>
        <v-date-picker
          :value="pickupDate"
          v-bind="attributes.datePicker"
          :disabled="disabled"
          :min="new Date().toISOString().substr(0, 10)"
          @input="emitUpdate('pickupDate', $event);showPickupDatePicker = false"
        />
      </v-menu>
    </v-col>

    <v-col
      md="8"
      cols="12"
      class="d-flex"
    >
      <v-autocomplete
        outlined
        @input="emitUpdate('course.fromTime', $event)"
        :value="courseData.fromTime"
        :items="hours(courseData.fromTime, courseData.toTime, true)"
        label="Przedział godzinowy"
        placeholder="Wpisz początek przedziału"
        :rules="[rules.required]"
        :disabled="disabled"
        color="primary"
        dense
      />
      <div class="mx-3 mt-2">
        &ndash;
      </div>
      <v-autocomplete
        :value="courseData.toTime"
        @input="emitUpdate('course.toTime', $event)"
        outlined
        :items="hours(courseData.fromTime, courseData.toTime, false)"
        placeholder="Wpisz koniec przedziału"
        :rules="[rules.required]"
        :disabled="disabled"
        color="primary"
        dense
      />
    </v-col>

    <v-col
      cols="12"
    >
      <v-text-field
        :value="courseData.timeNotes"
        label="Notatka do przedziału godzinowego"
        placeholder="Wpisz notatkę do przedziału godzinowego"
        outlined
        :disabled="disabled"
        @input="emitUpdate('course.timeNotes', $event)"
      />
    </v-col>

    <v-col
      v-if="!hidePhoneNumber"
      cols="12"
      :class="[swap||schedule ? 'pb-0' : '']"
    >
      <v-text-field
        outlined
        label="Dodatkowy numer telefonu"
        :value="orderData.phoneNumber"
        @input="emitUpdate('order.phoneNumber', $event)"
        :rules="[rules.phoneNumber]"
        :disabled="disabled"
        v-mask="getFieldMask('phoneNumber')"
        validate-on-blur
        placeholder="Wpisz numer telefonu"
      />
    </v-col>
    <v-col
      cols="12"
      v-if="swap && !schedule && !editMode"
      class="py-0"
    >
      <v-checkbox
        v-model="scheduledOrder"
        label="Chcę stworzyć cykliczne zlecenie"
        hide-details
        :disabled="disabled"
        class="mt-n2 mb-2 pt-0"
      />
    </v-col>
    <v-col
      v-if="scheduledOrder"
      cols="12"
      class="pb-0 my-2"
      :class="{ 'mt-4': schedule }"
    >
      <v-autocomplete
        outlined
        :items="days"
        label="Cykliczność"
        multiple
        item-text="label"
        item-value="value"
        placeholder="Wpisz rodzaj cyklu"
        :disabled="disabled"
        :value="orderData.scheduleDays"
        :rules="[rules.required]"
        @input="emitUpdate('order.scheduleDays', $event)"
        append-icon="mdi-chevron-down"
        dense
      />
    </v-col>

    <v-col v-if="isSimpleOrderType || swap">
      <v-autocomplete
        outlined
        :items="subjectTypes"
        :label="`Typ ${debrisStringified}`"
        :placeholder="`Wybierz typ ${debrisStringified}`"
        @input="emitUpdate('order.debrisTypeId', $event)"
        :value="orderData.debrisTypeId"
        validate-on-blur
        :item-text="getDebrisString"
        :disabled="disabled"
        item-value="id"
        :rules="[rules.required]"
        append-icon="mdi-chevron-down"
        dense
        :filter="searchDebrisType"
      />
    </v-col>

    <v-col
      cols="4"
      v-if="swap || courseData.courseType === 'Podstawienie'"
    >
      <v-autocomplete
        outlined
        :items="containerTypes"
        label="Zamówiony typ kontenera"
        placeholder="Wybierz typ kontenera"
        @input="emitUpdate('order.containerTypeId', $event)"
        :value="orderData.containerTypeId"
        :disabled="disabled"
        validate-on-blur
        item-text="name"
        item-value="id"
        :rules="[rules.required]"
        append-icon="mdi-chevron-down"
        dense
      />
    </v-col>

    <v-col
      cols="2"
      v-if="['Transport', 'Odbiór surowca'].includes(courseData.courseType)"
    >
      <v-text-field
        outlined
        label="Liczba ton"
        :value="orderData.aggregateAmount"
        validate-on-blur
        :disabled="disabled"
        :rules="[rules.required, rules.lowerThan(10000001)]"
        :placeholder="`Wpisz liczbę ${debrisStringified}`"
        @input="emitUpdate('order.aggregateAmount', $event)"
      />
    </v-col>

    <v-col
      v-if="(isSimpleOrderType || swap) && !orderData.anyContainer"
      cols="12"
      class="mt-2"
    >
      <v-textarea
        outlined
        :rows="2"
        auto-grow
        label="Notatka ogólna"
        :disabled="disabled"
        :value="orderData.userNotes"
        placeholder="Wpisz ogólną notatkę do zlecenia"
        @input="emitUpdate('order.userNotes', $event)"
      />
    </v-col>

    <v-col
      cols="12"
      class="pb-0"
    >
      <v-textarea
        outlined
        :rows="2"
        auto-grow
        hide-details
        :disabled="disabled"
        label="Notatka jednorazowa"
        @input="emitNotes"
        :value="courseData.userNotes"
        placeholder="Wpisz jednorazową notatkę do kursu"
      />
    </v-col>
  </v-row>
</template>

<script>
import rules from '../../../utils/validators'
import { dateStringFormat, getFieldMask, searchDebrisType, getDebrisString } from '../../../utils'
import attributes from '../../../const/datePickerAttrributes'
import hours from '../../../utils/availableHours'
import { mapState, mapActions } from 'vuex'

const days = [
  { label: 'Poniedziałek', value: 'monday' },
  { label: 'Wtorek', value: 'tuesday' },
  { label: 'Środa', value: 'wednesday' },
  { label: 'Czwartek', value: 'thursday' },
  { label: 'Piątek', value: 'friday' },
  { label: 'Sobota', value: 'saturday' }
]

export default {
  props: {
    courseData: {
      type: Object,
      default: () => {}
    },
    orderData: {
      type: Object,
      required: true
    },
    isSimpleOrderType: {
      type: Boolean,
      default: false
    },
    swap: {
      type: Boolean,
      default: false
    },
    schedule: {
      type: Boolean,
      default: false
    },
    editMode: {
      type: Boolean,
      default: false
    },
    hidePhoneNumber: {
      type: Boolean,
      default: false
    },
    orderTemplate: {
      type: Boolean,
      default: false
    },
    pickupDate: {
      type: String,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      rules,
      attributes,
      days,
      showDatePicker: false,
      showPickupDatePicker: false,
      scheduledOrder: false
    }
  },
  computed: {
    ...mapState({
      containerTypes: state => state.containerTypes.items,
      debrisTypes: state => state.debrisTypes.items,
      aggregateTypes: state => state.aggregateTypes.items,
      filters: state => state.core.filters,
      department: state => state.core.department
    }),
    newOrderCourseTypes () {
      return this.filters.courseTypes.filter(type => type !== 'Wymiana' && type !== 'Zabranie')
    },
    subjectTypes() {
      return this.courseData.courseType === 'Transport' ? this.aggregateTypes : this.debrisTypes
    },
    debrisStringified () {
      let string = 'odpadu'
      const { courseType } = this.courseData || {}
      if (courseType === 'Transport') string = 'kruszywa'
      if (courseType === 'Odbiór surowca') string = 'surowca'
      return string
    },
  },
  watch: {
    'courseData.courseType': {
      immediate: true,
      handler () {
        this.changeCourseTypeHandler()
      }
    },
    scheduledOrder (value) {
      if (!value) this.emitUpdate('order.scheduleDays', null)
    }
  },
  created () {
    if (this.schedule) this.scheduledOrder = true
  },
  mounted () {
    this.getContainerTypes({
      params: {
        departmentId: this.department.id
      }
    })
  },
  methods: {
    ...mapActions({
      getContainerTypes: 'containerTypes/getItems',
      getDebrisTypes: 'debrisTypes/getItems',
      getAggregateTypes: 'aggregateTypes/getItems'
    }),
    getFieldMask,
    hours,
    searchDebrisType,
    getDebrisString,
    formatDate (date) {
      const today = new Date().toISOString().substr(0, 10)
      if (date) {
        return date > today ? dateStringFormat(date) : dateStringFormat(today)
      } else {
        return ''
      }
    },
    changeCourseTypeHandler () {
      const aggregate = this.courseData.courseType === 'Transport'
      const fetchMethod = aggregate ? this.getAggregateTypes : this.getDebrisTypes
      const list = aggregate ? this.aggregateTypes : this.debrisTypes
      if (!list.length) {
        fetchMethod({ params: { aggregate, endpoint: 'debrisTypes' } })
      }
    },
    emitNotes (note) {
      this.emitUpdate('course.leaveNotes', note)
      this.emitUpdate('course.userNotes', note)
    },
    emitUpdate (key, value) {
      this.$emit('update', { key, value })
    }
  }
}
</script>
