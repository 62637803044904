export default function (fromTime, toTime, isLess) {
  let items = []
  const comparative = isLess ? toTime : fromTime
  let compareValue
  if (comparative?.length) {
    compareValue = comparative.split(':').shift()
  }
  for (let i = 6; i <= 23; i++) {
    items.push(i + ':00')
  }
  if (compareValue) {
    if (isLess) {
      items = items.filter((item) => {
        return +item.split(':').shift() < +compareValue
      })
    } else {
      items = items.filter((item) => {
        return +item.split(':').shift() > +compareValue
      })
    }
  }
  return items
}
